<template>
  <div class="main box-shadow-default">
    <div class="jzxx mb20"><h2>不动产登记申请表（签署文件）</h2></div>
    <div v-show="!htVisible">
      <div class="wxts">
        <div class="wxts-title">温馨提示：</div>
        <div>
          <p>
            1、待签署文件共有{{ fileText.length }}份：<span class="fontW">{{ fileText.join('、') }}</span>
            。须预览全部待签署文件，确认无误后签名和刷脸认证，且为本人自愿签名及认证，并对上述签名及认证的文件内容其真实性负责，如有不实，愿承担法律责任。
          </p>
          <p>2、已签名可刷新签名状态；如若重新签名可撤回认证状态。</p>
          <p>3、当申请人既是权利人又是代理人时，只需认证一次。</p>
        </div>
      </div>
      <div class="wrap">
        <div class="title top">
          <span>待签署人员信息</span>
          <div class="btnWrap">
            <el-button size="small" type="primary" @click="retractApply()">撤销</el-button>
            <!-- <el-button size="small" type="primary" @click="sendMessage()">短信通知</el-button> -->
            <el-button size="small" type="primary" @click="refresh()">刷新</el-button>
          </div>
        </div>
        <el-table
          :data="tableData"
          fit
          :header-cell-style="{ background: '#f0f9ff', color: '#333333', fontWeight: 'bold', fontSize: '15px' }"
          :span-method="objectSpanMethod"
          style="width: 100%"
        >
          <el-table-column prop="order" label="序号" width="55px"></el-table-column>
          <el-table-column prop="name" label="角色">
            <template slot-scope="{ row }">
              <span v-if="flowInfoIng.code === 'N200104'">{{ row.category === '1' ? '赠与方' : '受赠方' }}</span>
              <span v-else>{{ row.category === '1' ? '卖方' : '买方' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>

          <el-table-column prop="phone" label="手机号" width="130px"></el-table-column>
          <el-table-column label="待签署文件名称" width="268px">
            <template>
              <div v-for="(sign, i) in signFiles" :key="i">
                <span class="table-filetitle"> {{ i + 1 }}、{{ sign.clmc }} </span>
                <el-button type="text" icon="el-icon-view" @click="open(sign.fileUrl)">查看</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="sprz" label="认证状态">
            <template slot-scope="{ row }">
              <span :class="!row.sprz ? 'redPoint' : 'green09b11c'">{{ row.sprz ? '已认证' : '未认证' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="xwbl" label="签名状态">
            <template slot-scope="{ row }">
              <span :class="!row.xwbl ? 'redPoint' : 'green09b11c'">{{ row.xwbl ? '已签名' : '未签名' }}</span>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="phone" label="签名方式" width="160px">
            <template slot-scope="scope">
              <el-radio-group v-model="tableData[scope.$index].signType">
                <el-radio :label="1" :disabled="scope.row.xwbl || isShow">签字板签名</el-radio>
                <el-radio :label="2" :disabled="scope.row.xwbl || isShow">微信签名</el-radio>
              </el-radio-group>
            </template>
          </el-table-column> -->

          <el-table-column fixed="right" label="微信扫码认证" width="130px">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="goPhoneSqrz(scope.row)">微信签名认证 </el-button>
              <!-- <el-button
                :disabled="!scope.row.xwbl || !isShow"
                type="text"
                size="small"
                @click="goPhoneSqrz(scope.row)"
                v-if="!scope.row.fprz"
                >微信认证
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>

        <span class="redPoint noPerson" v-if="tableData.length <= 0">暂无认证申请人可直接进入下一步</span>
      </div>
      <rzewm ref="rzewm" @refresh="refresh"></rzewm>
      <div class="main-footer text-center mt20">
        <el-button type="primary" @click="prev()">上一步</el-button>
        <el-button type="primary" @click="next()">下一步</el-button>
      </div>
      <!-- 询问笔录弹窗 -->
      <question-record ref="questionRecord" @showSignButtonFn="showSignButtonFn" />
    </div>
    <!-- <template>
      <ht-record v-show="htVisible" ref="htRecord" @isVisisble="isVisisble" @getPDf="getFileList"></ht-record>
    </template> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import applicantCertifyService from '@/pages/iebdc/api/apply/applicant-certify';
// import applicantHtService from '@/pages/iebdc/api/apply/applicant-ht';
import rzewm from '../../component/rzewm';
import { isIe } from '@/pages/iebdc/utils/multibrowser';
import questionRecordService from '@/pages/iebdc/api/apply/question-record';
import { retractApply, printApply } from 'iebdc/api/my-application.js';
import applicantUploadService from '@/pages/iebdc/api/apply/applicant-upload';

var wgssSignatureSDK = null;
var sigObj = null;
var sigCtl = null;
var dynCapt = null;
import { REST_SERVICE } from '@iebdc/config/';
import questionRecord from '@/pages/iebdc/views/apply/flow/question-record';
// import htRecord from '@/pages/iebdc/views/apply/flow/ht-record';
import applicantFinishService from '@/pages/iebdc/api/apply/applicant-finish';
import applicantSaveService from '@/pages/iebdc/api/apply/applicant-save';

export default {
  name: 'sign-authorized',
  components: {
    rzewm,
    questionRecord,
    // htRecord
  },
  data() {
    return {
      tableData: [],
      name: '',
      flowCode: '',
      fileId: '',
      params: {
        sign: '',
        sqrId: '',
        ywh: '',
        isMainRecord: false,
        signIdCard: '',
        signName: '',
        signType: 1, //表示手写板签名
      },
      signFiles: [], //带签署文件
      // imgPDF: require('@/pages/iebdc/assets/images/wysq/pdf.png'),
      // pdfFileXwbl: require('@/pages/iebdc/assets/images/wysq/xwbl.jpg'),
      // pdfFileSqs: require('@/pages/iebdc/assets/images/wysq/sqs.jpg'),
      // pdfFileHt: require('@/pages/iebdc/assets/images/wysq/ht.png'),
      qmfs: true, //签名方式默认是
      isShow: true, //
      uploadFiles: [], //已上传材料名称列表
      htVisible: false, //是否展示合同
      Verification: true,
      timer: 10,
      showEditBtn: false, // 默认不展示修改询问笔录按钮
      isEditStatus: false, // 默认不是编辑询问笔录状态
      signBtnDisabled: false, // 待签署文件默认是可以点击状态
      isWithdrawn: false, //撤回按钮是否点击
      spanArr1: [],
      spanArr2: [],
      fileText: [], //提示文件列表
    };
  },
  computed: {
    // 下一步跳转所需数据
    ...mapState('wysq-step', ['nextItem', 'prevItem']),
    ...mapState('wysq-data', ['flowInfoIng', 'isPreviewDialog', 'disableOneWindow', 'firstFlow']),
    // 判断需埋点检测的流程
    isBuriedPoint() {
      let arrCode = ['N100301', 'N400301', 'N200110'];
      return arrCode.includes(this.firstFlow.thirdTypeData.netFlowdefCode);
    },
  },
  created() {
    this.targetToStep('身份认证');
    this.setrzzt();
    this.$bus.$on('authorBack', (msg) => {
      if (msg) {
        this.prev();
      }
    });
  },
  watch: {},
  methods: {
    ...mapMutations('wysq-data', ['setFlowInfoIng', 'getIsPreviewDialog', 'getDisableOneWindow']),
    // 跳转到下一页面
    ...mapMutations('wysq-step', ['targetToStep']),
    //解决项目打包上线后 点击el-image图片给body加上了overflow:hidden样式导致页面不可滑动问题
    removeStyle() {
      document.body.style.overflow = 'auto';
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      // if (columnIndex === 4) {
      if (columnIndex === 4) {
        const _row = this.spanArr1[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },

    dealData(data) {
      //请求回来的数据做处理
      this.tableData = [];
      data &&
        data.map((el) => {
          let newel = JSON.parse(JSON.stringify(el));
          newel.firstColumn = el.pIndexName;
          newel.secondColumn = el.indexName;
          this.tableData.push(newel);
        });
      this.spanArr1 = [];
      this.spanArr2 = [];
      for (var i = 0; i < this.tableData.length; i++) {
        if (i === 0) {
          this.spanArr1.push(1);
          this.pos1 = 0;
          this.spanArr2.push(1);
          this.pos2 = 0;
        } else {
          // 判断当前元素与上一个元素是否相同(第1)
          if (this.tableData[i].firstColumn === this.tableData[i - 1].firstColumn) {
            //那个字段要合并就判断上一个和下一个的字段是否相同
            this.spanArr1[this.pos1] += 1; //相同就累加
            this.spanArr1.push(0);
          } else {
            this.spanArr1.push(1); //
            this.pos1 = i;
          }
        }
      }
      // this.loading = false;
      // this.sendChild.isLoading = false;
    },
    // 跳转到下一页面,需先校验当前页面数据状态 TODO
    next() {
      // this.isBuriedPoint &&
      // _czc.push(['_trackEvent', this.firstFlow.thirdTypeData.name, '身份认证', '认证下一步', '1']);
      // if (this.flowInfoIng.code === 'N200110') {
      //   if (!this.uploadFiles.includes('询问笔录') || !this.uploadFiles.includes('不动产登记申请书')) {
      //     this.isBuriedPoint &&
      //     _czc.push(['_trackEvent', this.firstFlow.thirdTypeData.name, '身份认证', '认证下一步失败', '1']);
      //     return this.$message.error('请先填写完询问笔录，生成询问笔录PDF，申请表PDF');
      //   }
      //   if (!this.uploadFiles.includes('交易合同')) {
      //     this.isBuriedPoint &&
      //     _czc.push(['_trackEvent', this.firstFlow.thirdTypeData.name, '身份认证', '认证下一步失败', '1']);
      //     return this.$message.error('请先提取合同，获取交易合同PDF');
      //   }
      // } else {
      //   if (!this.uploadFiles.includes('询问笔录') || !this.uploadFiles.includes('不动产登记申请书')) {
      //     this.isBuriedPoint &&
      //     _czc.push(['_trackEvent', this.firstFlow.thirdTypeData.name, '身份认证', '认证下一步失败', '1']);
      //     return this.$message.error('请先填写完询问笔录，生成询问笔录PDF和申请表PDF');
      //   }
      // }
      // if (this.tableData.length <= 0) {
      //   this.$emit('updatePageNode', '4');
      //   this.isBuriedPoint &&
      //   _czc.push(['_trackEvent', this.firstFlow.thirdTypeData.name, '身份认证', '认证下一步成功', '1']);
      //   this.$router.push({
      //     name: this.nextItem.name
      //   });
      // } else {
      //   //判断需认证申请人是否已全部认证和签名
      //   let tag = true;
      //   this.tableData.forEach((item) => {
      //     // 验证结果yzjg、是否签名xwbl、视频认证结果sprz
      //     if (!item.sprz || !item.xwbl || !item.yzjg) {
      //       tag = false;
      //       // console.log(tag)
      //     }
      //   });
      //   if (tag) {
      //     this.$emit('updatePageNode', '4');
      //     this.isBuriedPoint &&
      //     _czc.push(['_trackEvent', this.firstFlow.thirdTypeData.name, '身份认证', '认证下一步成功', '1']);
      //     this.$router.push({
      //       name: this.nextItem.name
      //     });
      //   } else {
      //     this.$message.warning('请确认已全部签名和认证！');
      //     this.isBuriedPoint &&
      //     _czc.push(['_trackEvent', this.firstFlow.thirdTypeData.name, '身份认证', '认证下一步失败', '1']);
      //   }

      // }
      // 跳转材料邮寄办理方式
      if (!this.flowInfoIng.wwywh) return;
      this.targetToStep('填写申请表');
      if (this.step === 'VERIFYSUCCESS' || !this.$hasPermission('IEBDC:SQ:SQRZ')) {
        this.$router.push({
          name: this.nextItem.name,
        });
      } else {
        this.$message.warning('请先返回上一步完成身份认证！');
      }
    },
    // 打开询问笔录弹窗
    openQuestionRecord(status) {
      const tag = this.isSqrHasCertification();
      if (tag) return this.$message.warning('申请人已认证状态，修改询问笔录需先撤回认证状态！！！');
      this.isBuriedPoint &&
        _czc.push(['_trackEvent', this.firstFlow.thirdTypeData.name, '身份认证', '填写询问笔录', '1']);
      this.$refs.questionRecord.open();
    },
    async getContract() {
      if (!this.uploadFiles.includes('询问笔录') || !this.uploadFiles.includes('不动产登记申请书')) {
        return this.$message.error('请先填写完询问笔录和申请表，生成询问笔录PDF，申请表PDF');
      }
      const tag = this.isSqrHasCertification(); // 获取最新申请人认证状态
      if (tag) return this.$message.warning('申请人已认证状态，提取合同需先撤回认证状态！！！');
      this.$message.info('合同提取中');
      this.timer = 10;
      this.Verification = false;
      const auth_timer = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.Verification = true;
          clearInterval(auth_timer);
        }
      }, 1000);
      let params = {
        wwywh: this.flowInfoIng.wwywh,
        tsjgdm: this.flowInfoIng.tsjgdm,
      };
      // const res = await applicantHtService.getHtxxFileByTax(params);
      // if (res.success) {
      //   this.$message.success('合同文件替换成功');
      //   this.getFileList();
      // }
    },
    // 显示合同页面
    isVisisble() {
      this.htVisible = !this.htVisible;
      if (this.htVisible) {
        this.$refs.htRecord.getData();
      }
    },
    // 只生成不动产申请单
    showBdcapply() {
      if (!this.flowInfoIng.wwywh) return;
      printApply({ ywh: this.flowInfoIng.wwywh }).then((res) => {
        if (res.success) {
          this.getBdcApplyFileList();
        } else {
          this.$message.error('生成不动产申请单失败');
        }
      });
    },
    //显示询问笔录pdf
    showSignButtonFn() {
      this.isShow = false;
      this.showEditBtn = true;
      this.getFileList();
    },
    //刷新
    refresh() {
      this.applicantCertifyService();
      this.getFileList();
    },
    //发送短信
    async sendMessage() {
      const { success, data } = await applicantCertifyService.sendMsgToQlrAndYwr({ qlxxId: this.flowInfoIng.id });
      if (success && data) {
        this.$message.success('发送成功！');
      } else {
        this.$message.error('发送失败！');
      }
    },
    // 根据主业务号获取申请人的询问笔录/视频认证状态
    async applicantCertifyService(step) {
      if (!this.flowInfoIng.wwywh) return;
      const { success, data, message } = await applicantCertifyService.getSqrRzztByWwywh({
        wwywh: this.flowInfoIng.wwywh,
      });
      if (success && data) {
        let tag = true;
        (data || []).forEach((item, i) => {
          item.order = i + 1;
          if (item.signType) {
            item.signType = Number(item.signType);
          }
          if (!item.sprz || !item.xwbl || !item.yzjg) {
            tag = false;
          }
        });
        this.tableData = data;
        console.log(this.tableData);
        this.dealData(this.tableData);
        if (step && !tag) {
          this.fileUploadToQlxxStepByWwywh(step);
        }
      } else {
        this.$message.error(message);
      }
    },
    //获取无询问笔录文件列表
    async getBdcApplyFileList() {
      if (!this.flowInfoIng.wwywh) return;
      const { success, data, message } = await applicantUploadService.getUploadFilesCurrent({
        ywh: this.flowInfoIng.wwywh,
      });
      if (success && data) {
        this.signFiles.splice(0, this.signFiles.length);
        this.uploadFiles = [];
        (data || []).forEach((item) => {
          this.uploadFiles.push(item.clmc.substring(0, item.clmc.indexOf('.pdf')));
          if (item.clmc.indexOf('不动产登记申请书') > -1) {
            this.signFiles.push(item);
          }
          this.signFiles.forEach((res, index) => {
            if (res.clmc.indexOf('买卖合同') > -1 && this.flowInfoIng.code === 'N200110') {
              this.signFiles.splice(index, 1);
            }
          });
        });
        if (this.uploadFiles.includes('不动产登记申请书')) {
          this.isShow = false;
        }
      } else {
        this.$message.error(message);
      }
    },
    //获取文件列表
    async getFileList() {
      if (!this.flowInfoIng.wwywh) return;
      const { success, data, message } = await applicantUploadService.getUploadFilesCurrent({
        ywh: this.flowInfoIng.wwywh,
      });
      if (success && data) {
        this.signFiles = [];
        this.uploadFiles = [];
        (data || []).forEach((item) => {
          this.uploadFiles.push(item.clfl);
          // this.uploadFiles.push(item.clmc.substring(0, item.clmc.indexOf('.pdf')));
          // if (this.flowInfoIng.code === 'N200110') {
          if (
            item.clmc.indexOf('询问笔录') > -1 ||
            item.clmc.indexOf('不动产登记申请书') > -1 ||
            item.clmc.indexOf('合同') > -1 ||
            item.clmc.indexOf('房产情况告知承诺书') > -1
          ) {
            this.signFiles.push(item);
          }
          this.signFiles.forEach((res, index) => {
            if (res.clmc.indexOf('买卖合同') > -1) {
              this.signFiles.splice(index, 1);
            }
          });
          // }
          //  else if (item.clmc.indexOf('询问笔录') > -1 || item.clmc.indexOf('不动产登记申请书') > -1) {
          //   this.signFiles.push(item);
          // }
          this.signFiles.map((res) => {
            let index = res.clmc.indexOf('.');
            let name = res.clmc.substr(0, index);
            this.fileText.push(name);
          });
          this.fileText = [...new Set(this.fileText)];
        });
        if (this.flowInfoIng.code === 'N200110') {
          if (
            this.uploadFiles.includes('询问笔录') &&
            this.uploadFiles.includes('不动产登记申请书') &&
            this.uploadFiles.includes('交易合同')
          ) {
            this.isShow = false;
          } else {
            this.isShow = true;
          }
        } else if (this.uploadFiles.includes('询问笔录') && this.uploadFiles.includes('不动产登记申请书')) {
          this.isShow = false;
        }
        // 判断是否有询问笔录，如果有询问笔录，则显示修改询问笔录按钮（转移未备案）
        if (this.uploadFiles.indexOf('询问笔录') > -1) {
          this.isEditStatus = true; // 说明是修改状态
        }
      } else {
        this.$message.error(message);
      }
    },
    //提交申请
    save() {
      //判断需认证申请人是否已全部认证
      let tag = true;
      this.tableData.forEach((item) => {
        if (!item.sprz) {
          tag = false;
        }
      });
      if (tag) {
        this.next();
      } else {
        this.$message.warning('认证状态全部通过后才能提交申请！');
      }
    },
    //手写板签名的函数方法
    restartSession() {
      wgssSignatureSDK = null;
      sigObj = null;
      sigCtl = null;
      dynCapt = null;
      wgssSignatureSDK = new WacomGSS_SignatureSDK(this.onDetectRunning, 8000);
      this.timedDetect();
    },
    timedDetect() {
      if (wgssSignatureSDK.running) {
        this.start();
      }
    },
    onDetectRunning() {
      if (wgssSignatureSDK.running) {
        this.start();
      }
    },
    start() {
      if (wgssSignatureSDK.running) {
        sigCtl = new wgssSignatureSDK.SigCtl(this.onSigCtlConstructor);
      }
    },

    onSigCtlConstructor(sigCtlV, status) {
      if (wgssSignatureSDK.ResponseStatus.OK === status) {
        dynCapt = new wgssSignatureSDK.DynamicCapture(this.onDynCaptConstructor);
        // 去掉手写板的水印
        sigCtl.PutLicence(
          'eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI3YmM5Y2IxYWIxMGE0NmUxODI2N2E5MTJkYTA2ZTI3NiIsImV4cCI6MjE0NzQ4MzY0NywiaWF0IjoxNTYwOTUwMjcyLCJyaWdodHMiOlsiU0lHX1NES19DT1JFIiwiU0lHQ0FQVFhfQUNDRVNTIl0sImRldmljZXMiOlsiV0FDT01fQU5ZIl0sInR5cGUiOiJwcm9kIiwibGljX25hbWUiOiJTaWduYXR1cmUgU0RLIiwid2Fjb21faWQiOiI3YmM5Y2IxYWIxMGE0NmUxODI2N2E5MTJkYTA2ZTI3NiIsImxpY191aWQiOiJiODUyM2ViYi0xOGI3LTQ3OGEtYTlkZS04NDlmZTIyNmIwMDIiLCJhcHBzX3dpbmRvd3MiOltdLCJhcHBzX2lvcyI6W10sImFwcHNfYW5kcm9pZCI6W10sIm1hY2hpbmVfaWRzIjpbXX0.ONy3iYQ7lC6rQhou7rz4iJT_OJ20087gWz7GtCgYX3uNtKjmnEaNuP3QkjgxOK_vgOrTdwzD-nm-ysiTDs2GcPlOdUPErSp_bcX8kFBZVmGLyJtmeInAW6HuSp2-57ngoGFivTH_l1kkQ1KMvzDKHJbRglsPpd4nVHhx9WkvqczXyogldygvl0LRidyPOsS5H2GYmaPiyIp9In6meqeNQ1n9zkxSHo7B11mp_WXJXl0k1pek7py8XYCedCNW5qnLi4UCNlfTd6Mk9qz31arsiWsesPeR9PN121LBJtiPi023yQU8mgb9piw_a-ccciviJuNsEuRDN3sGnqONG3dMSA',
          this.onSigCtlPutLicence
        );
      }
    },

    onSigCtlPutLicence(sigCtlV, status) {
      if (wgssSignatureSDK.ResponseStatus.OK === status) {
        dynCapt = new wgssSignatureSDK.DynamicCapture(this.onDynCaptConstructor);
      }
    },

    onDynCaptConstructor(dynCaptV, status) {
      if (wgssSignatureSDK.ResponseStatus.OK === status) {
        sigCtl.GetSignature(this.onGetSignature);
      }
    },

    onGetSignature(sigCtlV, sigObjV, status) {
      if (wgssSignatureSDK.ResponseStatus.OK === status) {
        sigObj = sigObjV;
        sigCtl.GetProperty('Component_FileVersion', this.onSigCtlGetProperty);
      }
    },

    onSigCtlGetProperty(sigCtlV, property, status) {
      if (wgssSignatureSDK.ResponseStatus.OK === status) {
        dynCapt.GetProperty('Component_FileVersion', this.onDynCaptGetProperty);
      }
    },

    onDynCaptGetProperty(dynCaptV, property, status) {
      if (wgssSignatureSDK.ResponseStatus.OK === status) {
        if ('function' === typeof callback) {
          callback();
        }
      }
    },
    // 开始签名
    signClick(row) {
      this.params.sqrId = row.sqrId;
      this.params.ywh = this.flowInfoIng.wwywh;
      this.params.signIdCard = row.zjh;
      this.params.signName = row.name;
      if (!wgssSignatureSDK.running) {
        this.$confirm('暂未安装手写板驱动，是否立即安装？', '确认信息', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          let url = REST_SERVICE.files.getPluginFileByName + '?fileName=' + 'jingeshouxieban.zip';
          let a = document.createElement('a');
          a.href = url;
          a.download = 'file';
          a.click();
        });
      }
      dynCapt.Capture(sigCtl, 'who', 'why', null, null, this.onDynCaptCapture);
    },
    onDynCaptCapture(dynCaptV, SigObjV, status) {
      if (wgssSignatureSDK.ResponseStatus.INVALID_SESSION === status) {
        this.restartSession(window.Capture);
      } else {
        if (wgssSignatureSDK.DynamicCaptureResult.DynCaptOK !== status) {
          if (status === 103) {
            // 已安装驱动未插入手写板
            this.$message.error('请检查手写板是否插入成功！');
          }
        }
        switch (status) {
          case wgssSignatureSDK.DynamicCaptureResult.DynCaptOK:
            sigObj = SigObjV;
            var flags = wgssSignatureSDK.RBFlags.RenderOutputPicture | wgssSignatureSDK.RBFlags.RenderColor24BPP;
            sigObj.RenderBitmap(
              'bmp',
              60, //设置图片宽度
              40, //设置图片高度
              3, //设置字体大小
              0x00000000, //设置字体颜色
              0x00ffffff, //设置背景颜色
              flags,
              0,
              0,
              this.onRenderBitmap
            );
            break;
          default:
            break;
        }
      }
    },
    onRenderBitmap(sigObjV, bmpObj) {
      this.tableData.forEach((item) => {
        if (this.params.sqrId === item.sqrId) {
          if (isIe()) {
            //IE浏览器
            this.params.sign = bmpObj.image.href;
          } else {
            this.params.sign = bmpObj.image.currentSrc;
          }
          if (this.params.sign) {
            this.saveInfo();
          }
        }
      });
    },
    async saveInfo() {
      const xwblSuccess = questionRecordService.save(this.params);
      const htSuccess = questionRecordService.saveHtData({ ywh: this.flowInfoIng.wwywh });
      if (xwblSuccess.success && htSuccess.success) {
        this.refresh();
      }
    },
    //手写板签名的函数方法

    //获取认证二维码
    goPhoneSqrz(row) {
      this.isBuriedPoint && _czc.push(['_trackEvent', this.firstFlow.thirdTypeData.name, '身份认证', '微信签名', '1']);
      this.$refs.rzewm.getEwm(row);
    },
    //打开链接
    open(url) {
      this.removeStyle();
      window.open(url);
    },
    //上一步
    prev() {
      this.$confirm('如需返回修改内容，请先点击撤销按钮，继续返回只能查看', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '返回查看',
        cancelButtonText: '取消',
      }).then(() => {
        // this.isBuriedPoint && _czc.push(['_trackEvent', this.firstFlow.thirdTypeData.name, '身份认证', '上一步', '1']);
        // applicantSaveService.getProcessInfo({wwywh: this.flowInfoIng.wwywh}).then((res) => {
        //   if (['N200110', 'N200108', 'N200104'].includes(this.flowInfoIng.code)) {
        //     if (res.data.step === 'VERIFICATION' || res.data.step === 'VERIFYSUCCESS') {
        //       this.$store.commit('wysq-data/getIsPreviewDialog', false);
        //       this.$bus.$parent.disabled = true;
        //     } else {
        //       this.$store.commit('wysq-data/getIsPreviewDialog', true);
        //     }
        //   }
        // });
        // if (this.flowInfoIng.code === 'N200110') {
        //   this.$bus.$emit('isXwblNext', 'prev');
        // } else {
        //   this.$bus.$emit('isHtPre');
        // }
        // this.$bus.$emit('sfrzToPre', true);
      });
    },
    // 流程节点
    async updatePageNode(currentPoint) {
      if (this.point === currentPoint) return;
      const ywh = this.flowInfoIng.wwywh || this.$route.params.ywh;
      await applicantSaveService.recordPagePoint({
        wwywh: ywh,
        currentPoint,
      });
    },
    //撤回认证
    retractApply() {
      this.$confirm('撤销认证状态后将会清空签名以及认证信息，是否撤销？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        retractApply({ id: this.flowInfoIng.id }).then((res) => {
          if (res.success) {
            this.$message.success('撤回成功！');
            this.updatePageNode('3'); // 返回申请表页面节点
            applicantSaveService.getProcessInfo({ wwywh: this.flowInfoIng.wwywh }).then(() => {
              if (['N200110', 'N200108', 'N200104'].includes(this.flowInfoIng.code)) {
                this.$store.commit('wysq-data/getIsPreviewDialog', true);
              } else {
                this.$store.commit('wysq-data/getIsPreviewDialog', false);
              }
            });
            this.$router.push({
              name: 'applicantTable',
              params: { ywh: this.flowInfoIng.wwywh },
            });
            // 跳到申请表填写页面
            this.$bus.$emit('isXwblPre');
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    setrzzt() {
      console.log(JSON.parse(JSON.stringify(this.flowInfoIng)));

      applicantSaveService.getProcessInfo({ wwywh: this.flowInfoIng.wwywh }).then(async (result) => {
        if (result && result.success) {
          if (result.data.step === 'VERIFICATION') {
            this.fileUploadToQlxxStepByWwywh();
          } else if (result.data.step === 'VERIFYSUCCESS') {
            // 已经认证的情况下，再次跳转到申请表页面，会撤销用户认证情况，所以也需要撤销认证状态
            this.applicantCertifyService(result.data.step);
            this.getFileList();
          } else {
            this.getDetailData();
          }
        } else {
          this.getDetailData();
        }
      });
    },
    async fileUploadToQlxxStepByWwywh(step) {
      const res = await applicantFinishService.fileUploadToQlxxStepByWwywh({
        wwywh: this.flowInfoIng.wwywh,
      });
      if (res.success) {
        this.name = this.$store.getters.firstFlow ? this.$store.getters.firstFlow.thirdTypeData.name : '';
        this.flowCode = this.$store.getters.firstFlow ? this.$store.getters.firstFlow.thirdTypeData.netFlowdefCode : '';
        if (!step) {
          this.getDetailData();
        }
      } else {
        this.$message.error('认证中修改失败');
      }
    },
    getDetailData() {
      this.applicantCertifyService();
      this.getFileList();
    },
    // 判断申请人是否已经认证
    isSqrHasCertification() {
      let tag = false;
      this.tableData.forEach((item) => {
        if (item.sprz) {
          tag = true;
        }
      });
      return tag;
    },
  },
  mounted() {
    setTimeout(() => {
      this.restartSession();
    }, 3000);
  },
};
</script>

<style scoped lang="scss">
@import '~@/pages/iebdc/styles/public.scss';
@import '~@/pages/iebdc/styles/common-variables.scss';

.main {
  padding: 20px 40px;
  letter-spacing: 1px;
  background-color: $background-color-layout;

  .table-filetitle {
    display: inline-block;
    width: 188px;
  }

  .wxts {
    display: flex;
    padding: 20px 34px;
    background-color: #fff5eb;
    color: #ff0a0a;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;

    .wxts-title {
      width: 100px;
      min-width: 100px;
    }
  }

  // /deep/.el-table tbody tr:hover > td {
  //   background-color: #fff !important;
  // }
  /deep/ .el-table tbody tr td {
    .cell {
      font-size: 14px;
    }
  }

  p {
    margin: 0 0 10px;
    text-align: justify;
    text-justify: inter-ideograph;

    &.weight {
      font-weight: 700;
      color: rgba(85, 85, 85, 1);
    }
  }

  .wrap {
    // border: 1px solid #dddddd;
    margin-bottom: 20px;

    .title {
      font-size: 16px;
      color: #333333;
      line-height: 50px;
      padding-left: 10px;
      overflow: hidden;

      .btnWrap {
        float: right;
        text-align: right;
      }

      .tip {
        font-style: normal;
        color: red;
        font-size: 12px;
        margin-left: 10px;
      }
    }

    .top {
      padding-left: 0;
      margin-bottom: 10px;
      border-bottom: 1px solid #e6ebf5;

      span {
        float: left;
        padding: 0 5px;
        height: 34px;
        line-height: 30px;
        margin-top: 20px;
        border-bottom: 2px solid #2c8bff;
      }
    }

    .noPerson {
      line-height: 50px;
      margin-left: 10px;
    }
  }

  .authWrap {
    border: 1px solid #e6ebf5;

    .title {
      background: #f2f6fc;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
    }

    .imgWrap {
      width: 100%;
      height: 1100px;
      position: relative;

      img {
        width: 95%;
        height: 95%;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  .type {
    span {
      font-weight: 700;
    }
  }

  /deep/ .el-table {
    border: 1px solid #e6ebf5;
    border-bottom: none !important;
  }

  /deep/ .el-table th {
    height: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    background: #f2f6fc;
  }

  /deep/ .el-table tr {
    th:first-of-type,
    td:first-of-type {
      text-align: center;
    }
  }

  .redPoint {
    color: #ff5b24;
  }

  .green09b11c {
    color: #09b11c;
  }
}

.filelist {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;

  .filelist-title {
    background-color: #f6f6f6;
    padding: 0 20px;
    height: 60px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;

    .clmc {
      font-size: 18px;
      color: #343434;
    }

    .tips {
      color: red;
      font-size: 14px;
      margin-right: $spacing-base;
    }

    .redPoint {
      color: red;
      font-size: 18px;
    }

    .hasMore {
      float: right;
      margin-top: 7px;
    }
  }

  .wjylBox {
    min-height: 180px;
    border-bottom: 1px solid #e5e5e5;
  }

  .innerBox {
    height: 180px;
    border-bottom: 1px solid #e5e5e5;
  }

  .filelist-list {
    display: flex;
    flex-wrap: wrap;
    min-height: 282px;
    background: #ffffff;

    > div {
      width: 25%;
    }
  }

  .itemPic {
    display: inline-block;
    width: 25%;
    text-align: center;
    padding-bottom: $spacing-medium;

    .title {
      line-height: 15px;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;

      .redTitle {
        color: #d40000;
        font-size: 14px;
        font-weight: 700;
      }

      .blackColor {
        color: #353535;
      }
    }

    .picture {
      margin: 0 $spacing-medium $spacing-medium $spacing-medium;
      border: 1px dashed #2c8cff;
      height: 180px;
      display: flex;
      justify-content: center;
      overflow: hidden;

      .el-image {
        display: flex;
        align-items: center;
        cursor: pointer;

        /deep/ .el-image__inner {
          width: initial;
          height: initial;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .minWidth {
      min-width: 80px;
    }
  }
}
</style>
